<template>
  <div>
    <v-row style="margin-top: 13%" align="center" justify="space-around">
      <v-card elevation="2" outlined style="width: 500px" :loading="loading">
        <v-container style="text-align: center;">
          <h1>Enter New Password</h1>
          <v-divider></v-divider>
        </v-container>
        <v-container style="text-align: center;">
          <p>Welcome, {{ user.given_name }} {{ user.family_name }}!</p>
        </v-container>
        <v-form ref="form" lazy-validation style="padding: 0px 30px 30px 30px" v-model="valid">
          <v-row>
              <v-col cols="6">
                  <v-text-field
                  ref="password_input"
                  v-model="password"
                  :rules="passwordRules"
                  v-on:keyup.enter="updateUserPassword"
                  label="Password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  required
                  ></v-text-field>
              </v-col>
              <v-col cols="6">
                  <v-text-field
                  ref="passwordConfirm_input"
                  v-model="passwordConfirm"
                  :rules="passwordConfirmRules"
                  v-on:keyup.enter="updateUserPassword"
                  label="Confirm Password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  required
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row>
            <div style="padding: 16px 10px 10px 10px; width: 100%">
              <v-btn block color="blue" class="white--text" @click="updateUserPassword()" :disabled="!valid">
                Update Password
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        user: {},
        valid: true,
        password: "",
        passwordRules: [
          v => !!v || "Password is required",
          v => (v || '').length >= 8 || 'Password must be at least 8 characters long',
        v => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]\\|;:'"?/>.<,])[A-Za-z\d!@#$%^&*()_\-+={}[\]\\|;:'"?/>.<,]{8,}$/.test(v) || 'Password must contain at least one letter, number and special character'
        ],
        passwordConfirm: "",
        passwordConfirmRules: [v => !!v || "Confirm password is required", () => this.password === this.passwordConfirm || "Passwords must match"],
        showPassword: false,
        loading: false,
      };
    },
    mounted: function() {
      this.$refs.password_input.focus();
      this.getUser();
    },
    methods: {
      updateUserPassword() {
        if (this.$refs.form.validate()) {
          this.loading = true;
          this.$axios.put(`${process.env.VUE_APP_API_BASE_URL}/users/resetpassword/${this.$route.query.password_reset_token}`, {password: this.password})
          .then(() => {
            this.loading = false;
            this.$store
              .dispatch("login", {"userNameOrEmail": this.user.email, "password": this.password})
              .then(() => {
                this.$router.push("/");
              })
              .catch(() => {
                this.showAlert = true;
                this.showLoader = false;
              });
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.$router.push('/password-reset');
            this.$store.dispatch("showSnackbar", {text: "Password Reset Timed Out! Please try again.", color: "error", timeout: 5000});
          });
        }
      },
      getUser() {
        this.loading = true;
        this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/resetpassword/${this.$route.query.password_reset_token}`)
        .then(result => {
          this.user = result.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$router.push('/password-reset');
          this.$store.dispatch("showSnackbar", {text: "Password Reset Timed Out! Please try again.", color: "error", timeout: 5000});
        });
      }
    }
  };
</script>
